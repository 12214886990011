import React, { Component }       from 'react';

import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom'

import './App.scss';

import BlockDashboard from "./dashboard/BlockDashboard";
import PageDetail from "./pages/PageDetail";

class App extends Component {

  render() {
    return (
      <div className="app__main-app-container" id="app-container">
        <Router>
          <div className="app__main-conent-container">
            <Route exact path="/resume" component={() => <BlockDashboard />}/>
            <Route exact path="/detail/:detailkey" component={() => <PageDetail />}/>
            <Route exact path="/" component={() => <BlockDashboard />}/>
          </div>
        </Router>
      </div>
    );
    return <div></div>
  }
}

export default App;