import React, { Component }   from 'react';

import axios                  from "axios";
import * as THREE             from "three";
import { OrbitControls, TrackballControls }      from 'three-orbitcontrols-ts';
import testStl                from "./stls/motoradapter.stl";

import expand                 from "../images/white_expand.svg";
import close                  from "../images/x-close.svg";

import "./StlRenderer.scss";

const STLLoader               = require('three-stl-loader')(THREE)

class StlRenderer extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, this.el.clientWidth/this.el.clientHeight, 0.1, 10000 );
    var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize( this.el.clientWidth, this.el.clientHeight );
    this.el.appendChild( renderer.domElement );
    var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );

    var material = new THREE.MeshPhongMaterial({ 
        color: 0x333333, 
        specular: 100, 
        shininess: 100 });
    
    var loader = new STLLoader()
     
    let stl = null;
    let stlOutline = null;

    let stlPosition = this.getStlPosition();
    loader.load(this.getStl(), (geometry) => {

        var material = new THREE.MeshPhongMaterial({
            color: 0x222222, 
            specular: 100, 
            shininess: 100 });
        var edges = new THREE.EdgesGeometry( geometry );
        var line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x888888 } ) );
        
        scene.add( stlOutline );

        var mesh = new THREE.Mesh(geometry, material);
            scene.add(mesh);
            scene.add(line);
            stl = mesh;
            stlOutline = line;

            // line.position.y -= 10;
            // mesh.position.y -= 10;
            line.rotation.x = stlPosition.xRotation;
            mesh.rotation.x = stlPosition.xRotation;
            line.rotation.y = stlPosition.yRotation;
            mesh.rotation.y = stlPosition.yRotation;
            line.position.y = stlPosition.y;
            mesh.position.y = stlPosition.y;
            line.position.x = stlPosition.x;
            mesh.position.x = stlPosition.x;
    })

    scene.add(new THREE.HemisphereLight(0xffffff, 1.5));

    camera.position.z = stlPosition.zoom === undefined ? 25 : stlPosition.zoom;

    var controls = new OrbitControls(camera, renderer.domElement);

    controls.enableDamping = true;
    controls.enableZoom = true;

    window.addEventListener('resize', () => {
      if (this.el) {
        renderer.setSize(this.el.clientWidth, this.el.clientHeight);
        camera.aspect = this.el.clientWidth/this.el.clientHeight;
        camera.updateProjectionMatrix();
      }
    }, false);

    var animate = function () {
      requestAnimationFrame( animate );
      if (stl) {
        // stl.rotation.x += 0.01;
        // stl.rotation.y += 0.001;
        // stlOutline.rotation.y += 0.001;
      }
      controls.update();
      camera.updateProjectionMatrix();
      renderer.render( scene, camera );
    };

    animate();
  }

  getStlPosition() {
    if (this.props.page.stl_pos) {
      return this.props.page.stl_pos;
    } else {
      return {
        y: -10,
        x: 0,
        xRotation: 0,
        yRotation: 0
      }
    }
  }

  getStl() {
    let stlLoc = this.props.page.stl;
    return stlLoc ? stlLoc : testStl;
  }

  renderExpandIcon() {
    if (this.props.hideExpand === true) {
      return undefined;
    }
    return <img onClick={ this.props.onExpand } className="control expand" src={expand} />;
  }

  render() {
    return (
      <div ref={ref => (this.el = ref)} className="stl__container">
        <div className="controls">
          { this.renderExpandIcon() }
          <img onClick={ this.props.onClose } className="control" src={close} />
        </div>
      </div>
    );
  }
}

export default StlRenderer;