import React, { Component }   from 'react';

import axios                  from "axios";
import rabbitLabsLogo         from "../images/ic_rabbitlabs_logo.png";
import hamburger              from "../images/hamburger.svg";
import close                  from "../images/close.svg";

import Header                 from "../header/Header";
import Menu                   from "../menu/Menu";
import Footer                 from "../footer/Footer";
import FilterList             from "../dashboard/FilterList";

import "./PageDetail.scss";

class PageDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuShowing: false,
      page: {}
    };
  }

  componentDidMount() {
    let urlParts = window.location.hash.split("/");
    let key = urlParts[urlParts.length - 1];
    if (key) {
      axios.get(`/pages/${key}/metadata.json`).then(res => {
        this.setState({ page: res.data })
      })
    }
  }

  render() {
    return (
      <div className="page-detail__container">
        <Header onClick={ () => this.setState({ menuShowing: !this.state.menuShowing }) }  />
        <FilterList static={true} page={ this.state.page} />
        <Menu menuShowing={ this.state.menuShowing } onMenuHidden={ () => this.setState({ menuShowing: !this.state.menuShowing }) }/>
        <img className="feature-img" src={ this.state.page.feature_img } />
        <div className="main-content">
          <div className="content">
            <div className="title">
              { this.state.page.title }
            </div>
            <div className="text-content">
              Enjoyed minutes related as at on on. Is fanny dried as often me. Goodness as reserved raptures to mistaken steepest oh screened he. Gravity he mr sixteen esteems. Mile home its new way with high told said. Finished no horrible blessing landlord dwelling dissuade if.

 

Rent fond am he in on read. Anxious cordial demands settled entered in do to colonel. Far quitting dwelling graceful the likewise received building. An fact so to that show am shed sold cold. Unaffected remarkably get yet introduced excellence terminated led. Result either design saw she esteem and. On ashamed no inhabit ferrars it ye besides resolve. Own judgment directly few trifling. Elderly as pursuit at regular do parlors. Enjoyed minutes related as at on on. Is fanny dried as often me. Goodness as reserved raptures to mistaken steepest oh screened he. Gravity he mr sixteen esteems.
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PageDetail;