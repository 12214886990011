import React, { Component }   from 'react';

import "./FilterList.scss";

const FILTERS = [
  "All", "Software", "Hardware"
]

const COLORS = [
  "#000000"
]

class FilterList extends Component {

  constructor(props) {
    super(props);

    let initialMap = new Map();
    initialMap.set("All", true);
    this.state = {
      selectedFilters: initialMap
    }
  }

  selectFilter(filter) {
    let selectedFilters = this.state.selectedFilters;
    // if (filter === 'All') {
    //   if (selectedFilters.has('All')) {
    //     // Do nothing
    //   } else {
    //     FILTERS.forEach(filter => selectedFilters.delete(filter))
    //     selectedFilters.set('All', true);
    //   }
    // } else if (selectedFilters.has(filter)) {
    //   selectedFilters.delete(filter);
    //   if (selectedFilters.size === 0) {
    //     selectedFilters.set('All', true);
    //   }
    // } else {
    //   selectedFilters.delete('All');
    //   selectedFilters.set(filter, true);
    // }
    selectedFilters.clear();
    selectedFilters.set(filter, true);
    this.setState({ selectedFilters: selectedFilters })

    if (this.props.onFilterChanged) {
      this.props.onFilterChanged(selectedFilters);
    }
  }

  renderFilters() {
    if (this.props.static && this.props.page && this.props.page.tags) {
      return this.props.page.tags.map((filter, i) => {
        return <div key={i} className={`filter selected ${this.props.projectsShowing ? 'show' : 'hide'}`}>
          {filter}
          <div className="underline"></div>
        </div>
      })
    } else {
      return FILTERS.map((filter, i) => {
        return <div key={i} 
                    onClick={ () => this.selectFilter(filter) }
                    className={`filter ${this.state.selectedFilters.has(filter) ? 'selected' : ''}  ${this.props.projectsShowing ? 'show' : 'hide'}`}>
          {filter}
          <div className="underline"></div>
        </div>
      })
    }
  }

  render() {
    return (
      <div className={`filter-list__container ${this.props.show ? 'show' : 'hide'}`}>
        <div className="title">Rabbit Labs</div>
        <div className="filters">
          <div className="links">
            <div onClick={ this.props.onLinkSelected.bind(this, "Projects") } className={`link ${this.props.selected === 'Projects' ? 'selected' : ''}`}>
              <div>Projects</div>
              <div className={`underline`}></div>
            </div>
            <div onClick={ this.props.onLinkSelected.bind(this, "About") } className={`link ${this.props.selected === 'About' ? 'selected' : ''}`}>
              <div>About</div>
              <div className={`underline`}></div>
            </div>
            <div onClick={ this.props.onLinkSelected.bind(this, "Contact") } className={`link ${this.props.selected === 'Contact' ? 'selected' : ''}`}>
              <div>Contact</div>
              <div className={`underline`}></div>
            </div>
          </div>
          { this.renderFilters() }
        </div>
      </div>
    );
  }
}

export default FilterList;