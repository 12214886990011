import React, { Component }   from 'react';

import close                  from "../images/close.svg";

import "./Menu.scss";

class Menu extends Component {

  renderMenuItems() {
    return ["Projects", "About", "Contact"].map((item, i) => {
      return <div key={i} onClick={ () => this.props.show(item) } className={ `item ${item === this.props.location ? 'highlighted' : ''}`}>
              { item }
            </div>
    })
  }

  render() {
    return (
      <div className="menu__container">
        <div className={`menu__menu ${this.props.menuShowing ? 'show' : 'hide' }`}>
          <img onClick={ this.props.onMenuHidden } className="hamburger" src={ close } />
          <div className="menu-items">
            { this.renderMenuItems() }
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;