import React, { Component }   from 'react';
import axios                  from "axios";
import StlRenderer            from '../stl_renderer/StlRenderer'
import close                  from "../images/close.svg";

import "./PageBlock.scss";

class PageBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showResults: false,
      descriptorSelected: false
    }
  }

  onMouseEnter(e) {

  }

  expandStl() {
    if (this.props.onExandStl) {
      this.props.onExandStl();
      this.setState({showResults: false})
    }
  }

  renderResults() {
    if (this.state.showResults) {
      return <StlRenderer
                page={ this.props.page } 
                onClose={ () => this.setState({showResults: !this.state.showResults}) } 
                onExpand={ this.expandStl.bind(this) } 
              />
    }
    return null;
  }

  renderAction() {
    if (this.props.page.type === 'site' && this.props.page.video) {
      return (
        <div className="action-button" onClick={ () => this.props.onExpandVideo(this.props.page.youtube) }>
          <div className="action site">
            PREVIEW
          </div>
        </div>)
    } else if (this.props.page.type === 'site') {
      if (this.props.page.link) {
        return (<a href={ this.props.page.link }>
          <div className="action-button">
            <div className="action site">
              SHOW ME
            </div>
        </div></a>)
      }
    } else if (this.props.page.type === 'hardware') {
      return (<div className="action-button" onClick={ () => this.setState({showResults: !this.state.showResults})}>
          <div className="action">
          RENDER
        </div>
      </div>)
    }
  }

  onDescriptorClick() {
    this.setState({
      descriptorSelected: !this.state.descriptorSelected
    })
  }

  renderAdditionalLines() {
    return this.props.page.additional.map(line => { return <div>{ line }</div> });
  }

  renderMoreLink() {
    if (this.props.page.link) {
      return <div className="more-link">
        See full project <a target="_blank" href={ this.props.page.link }>here</a>.
      </div>
    }
  }

  renderActionLink() {
    if (this.props.page.type === 'site' && this.props.page.video) {
      return <div onClick={ () => this.props.onExpandVideo(this.props.page.youtube) } className="more-link all">
              See a preview
            </div>
    } else if (this.props.page.type === 'hardware') {
      return <div onClick={ () => this.setState({showResults: !this.state.showResults})} className="more-link all">
              See a render
            </div>
    }
  }

  renderAdditional() {
    if (this.props.page.additional) {
      return <div className="additional">
        { this.renderAdditionalLines() }
        { this.renderMoreLink() }
        { this.renderActionLink() }
      </div>
    } else if (this.props.page.stl) {
      return <div className="additional">
        { this.renderActionLink() }
      </div>
    }
  }

  renderMoreSection() {
    if (this.props.page.additional) {
      return <div onClick={ () => this.setState({descriptorSelected: true})} className="more">More</div>
    } else if (this.props.page.stl) {
      return <div onClick={ () => this.setState({descriptorSelected: true})} className="more">More</div>
    }
  }

  renderPage() {
    if (this.props.page) {

      this.canvasRef = React.createRef();
      this.imageRef = React.createRef();

      if (this.props.index % 2 == 0) {
        return <div className="page-block__item">
          <div className="line-label"></div>
          <div className="img-container right">
            <div className="page-separator"></div>
            <img ref={ this.imageRef }  id={ `theimage${this.props.index}` } src={ this.props.page.feature_img } />
            <div onClick={ () => window.alert('it work')} className="hover-mask">
              <div className="background-mask"></div>
            </div>
            { this.renderAction() }
            { this.renderResults() }
            <div 
              className={
                `descriptor ${
                  this.state.showResults ? 'hidden' : '' } 
                  ${ this.state.descriptorSelected ? 'expand' : 'contract'}
                  `}>
              <div className="descriptor-body">
                <div className="title">{ this.props.page.title }</div>
                <div className="subtitle">{ this.props.page.subtitle }</div>

                { this.renderMoreSection() }
                { this.renderAdditional() }
              </div>

              <img onClick={ () => this.setState({descriptorSelected: false}) } className="close" src={close} />
            </div>
          </div>
        </div>
      } else {
        return <div className="page-block__item">
          <div className="img-container left">
            <img ref={ this.imageRef }  id={ `theimage${this.props.index}` } src={ this.props.page.feature_img } />
            <div className="hover-mask">
              <div className="background-mask"></div>
            </div>
            { this.renderAction() }
            { this.renderResults() }
            <div className="page-separator"></div>
            <div
              className={
                `descriptor ${
                  this.state.showResults ? 'hidden' : '' } 
                  ${ this.state.descriptorSelected ? 'expand' : 'contract'}
                  `}>
              <div className="descriptor-body">
                <div className="title">{ this.props.page.title }</div>
                <div className="subtitle">{ this.props.page.subtitle }</div>

                { this.renderMoreSection() }
                { this.renderAdditional() }
              </div>

              <img onClick={ () => this.setState({descriptorSelected: false}) } className="close" src={close} />
            </div>
          </div>
          <div className="line-label"></div>
        </div>
      }
    }
  }

  render() {
    return (
      <div onClick={ this.props.onClick } className={`page-block__container`}>
        { this.renderPage() }
      </div>
    );
  }
}

export default PageBlock;