import React, { Component }   from 'react';

import rabbitLabsLogo         from "../images/ic_rabbitlabs_logo.svg";
import hamburger              from "../images/hamburger.svg";

import "./Header.scss";

class Header extends Component {

  render() {
    return (
      <div className="header__container">
        <div className="header__header">

        </div>
      </div>
    );
  }
}

export default Header;