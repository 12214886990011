import React, { Component }   from 'react';

import "./Footer.scss";

class Footer extends Component {

  render() {
    return (
      <div className="footer__container">
        <div className="links">
          <a href="/#/"><div className="link">Home</div></a>
          <a href="/#/about"><div className="link">About</div></a>
          <a href="/#/contact"><div className="link">Contact</div></a>
        </div>
        <div className="copyright">Copyright ©2019 Rabbit Labs</div>
      </div>
    );
  }
}

export default Footer;