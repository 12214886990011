import React, { Component }   from 'react';

import axios                  from "axios";
import rabbitLabsLogo         from "../images/ic_rabbitlabs_logo.svg";
import hamburger              from "../images/hamburger.svg";
import close                  from "../images/close.svg";
import aboutMe                from "../images/about.jpg";
import contact                from "../images/contact.jpg";
import luke_contact           from "../images/luke.jpeg";

import PageBlock              from "../pages/PageBlock";
import Header                 from "../header/Header";
import Menu                   from "../menu/Menu";
import Footer                 from "../footer/Footer";
import FilterList             from "./FilterList";
import StlRenderer            from "../stl_renderer/StlRenderer";
import ReactPlayer            from 'react-player'
import {withRouter}           from 'react-router-dom';

import "./BlockDashboard.scss";

class BlockDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuShowing: false,
      expandVideoPlayer: false,
      filterList: new Map().set("All", true),
      pages: [],
      volume: 0,
      currentPage: 'Projects'
    };

    this.navigateTo = this.navigateTo.bind(this);
  }

  componentDidMount() {

    axios.get("/www/pages.json").then(res => {
      this.setState({ pages: res.data }, () => {
        if (window.location.href.includes("resume")) {
          axios.get("/hidden_pages").then(res => {
            this.setState({pages: res.data.concat(this.state.pages)})
          }, err => {
            if (err.response.status === 401) {
              this.setState({showLogin: true });
              // console.log(window.alert('login'));
            }
          });
        }
      })
    })
  }

  getPages() {
    if (this.state.filterList.has("All")) {
      return this.state.pages;
    } else {
      let filteredPages = [];
      this.state.pages.forEach(page => {
        for (let tag of page.tags) {
          if (this.state.filterList.has(tag)) {
            filteredPages.push(page);
            break;
          }
        }
      })
      return filteredPages
    }
  }

  onExandStl(page) {
    this.setState({expandedStlPage: page })
  }

  onExpandVideo(page) {
    if (page.video) {
      this.setState({ expandedVideo: page.video, volume: page.volume ? page.volume : 0 })
    }
  }

  renderDashboardContent() {
    let pages = this.getPages()
    if (pages && pages.length > 1) {
      return pages.map((page, index) => {
        return <PageBlock
                    key={index}
                    onExandStl={ this.onExandStl.bind(this, page) }
                    onExpandVideo={ this.onExpandVideo.bind(this, page) }
                    page={page}
                    index={index} />
      })
    }
  }

  renderStlRendererContainer() {
    if (this.state.expandedStlPage) {
      return (<div className="video_container">
        <StlRenderer
          hideExpand={true}
          onClose={ () => this.setState({ expandedStlPage: null })}
          page={ this.state.expandedStlPage } />
        <img onClick={ () => this.setState({expandedStlPage: null}) } className="close" src={close} />
      </div>)
    }
  }

  renderStlRenderer() {
    return (<div className={`block_dashboard__stl_renderer ${this.state.expandedStlPage ? 'show' : 'hide'}`}>
      <div onClick={ () => this.setState({expandedStlPage: null}) } className="backdrop"></div>
      { this.renderStlRendererContainer() }
    </div>);
  }

  renderVideoPlayerContainer() {
    if (this.state.expandedVideo) {
      return (<div className="video_container">
        <ReactPlayer
          playing={false}
          width="100%"
          height="100%"
          volume={ this.state.volume }
          controls={true}
          url={ this.state.expandedVideo } />
          <img onClick={ () => this.setState({expandedVideo: null}) } className="close" src={close} />
      </div>);
    }
  }

  renderVideoPlayer() {
    return (<div className={`block_dashboard__video_player ${this.state.expandedVideo ? 'show' : 'hide'}`}>
      <div onClick={ () => this.setState({expandedVideo: null}) } className="backdrop"></div>
      { this.renderVideoPlayerContainer() }
    </div>);
  }

  navigateTo(page) {
    window.location.href = `/#/detail/${page.key}`;
  }

  renderFirstItem() {
    let pages = this.getPages()
    if (pages && pages.length >= 1) {
      return <div onClick={ () => this.navigateTo(pages[0]) } className="first-page">
                <PageBlock firstPage={true} page={pages[0]} />
        </div>
    }
  }

  onFilterChanged(filterList) {
    this.setState({ filterList: filterList })
  }

  renderProjectsTitle() {
    if (this.state.filterList.has("All")) {
      return "All Projects";
    } else if (this.state.filterList.has("Hardware")) {
      return "Hardware Projects";
    } else if (this.state.filterList.has("Software")) {
      return "Software Projects";
    } else {
      return "All Projects";
    }
  }

  onLinkSelected(link) {
    this.setState({ currentPage: link })
  }

  login() {
    axios.get("/login", {
      auth: {
        username: this.refs.email.value,
        password: this.refs.password.value
      }
    }).then(res => {
      res.data.concat(this.state.pages)
      this.setState({pages: res.data, showLogin: false})
    });
  }

  renderContent() {
    return (<div className="block_dashboard__content">
      <div className={`block_dashboard__about ${this.state.currentPage === 'About' ? 'show' : 'hide'}`}>
        <div className="block_dashboard__about-content">
          <img src={ aboutMe } />
          <div className="block_dashboard__description">
            <div className="title">About</div>
            <div className="description">
              <br />
              <span>
                I'm a software engineer by trade, an electrical engineer by schooling, and generally a admirer of all things built well.
              </span>
              <br />
              <br />
              <span>
                In 2012 while at university, I designed my first Android app/product, a bluetooth-connected hip-airbag for the elderly. It wasn't the best idea I would ever have, and I nearly blew my eardrums out the first time it exploded, but building it was enough to get me hooked.
                <br />
                <br />
                Since that day, my work has run the gamut from standard app and web development, to robotic baby products, to musical fruit inflatables played by the Flaming Lips; every project unique and rewarding in its own way. I'm not sure what comes next, but I'm sure I'll enjoy the journey.
              </span>
              <span>
                
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`block_dashboard__about contact ${this.state.currentPage === 'Contact' ? 'show' : 'hide'}`}>
        <div className="block_dashboard__about-content">
          <img className="small" src={ contact } />
          <div className="block_dashboard__description">
            <div className="description">
              <div className="item">Mike Derrick</div>
              <div className="item">Co-Owner</div>
              <div className="item">Email: <a href="mailto:mike@rabbitlabs.io">mike@rabbitlabs.io</a></div>
            </div>
          </div>
        </div>
        <div className="block_dashboard__about-content">
          <img className="small" src={ luke_contact } />
          <div className="block_dashboard__description">
            <div className="description">
              <div className="item">Luke Derrick</div>
              <div className="item">Co-Owner</div>
              <div className="item">Email: <a href="mailto:luke@rabbitlabs.io">luke@rabbitlabs.io</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`block_dashboard__grid-items ${this.state.currentPage === 'Projects' ? 'show' : 'hide'}`}>
        <div className="descriptor-title">{ this.renderProjectsTitle() }</div>
        { this.renderDashboardContent() }
      </div>
    </div>)
  }

  renderFooter() {
    return <Footer />;
  }

  render() {
    return (
      <div className="block_dashboard__container">
        <img
          className={`hamburger ${(this.state.expandedStlPage || this.state.expandedVideo) ? 'hide' : 'show' }`}
          src={hamburger}
          onClick={() => this.setState({menuShowing: true})} />
        <FilterList
          projectsShowing={ this.state.currentPage === 'Projects' }
          show={ !this.state.expandedVideo }
          selected={ this.state.currentPage }
          onLinkSelected={ this.onLinkSelected.bind(this) }
          onFilterChanged={ this.onFilterChanged.bind(this) } />
        { this.renderContent() }
        { this.renderStlRenderer() }
        { this.renderVideoPlayer() }
        <Menu
            show={ link => this.setState({ currentPage: link, menuShowing: false })}
            location={ this.state.currentPage }
            onMenuHidden={ () => this.setState({menuShowing: false })}
            menuShowing={ this.state.menuShowing }></Menu>

        <div className={`login-form ${this.state.showLogin ? 'show' : 'hide'}`}>
          <div className="backdrop">

            <div className="theform">
              <div className="inputlabel">Email</div>
              <input ref="email" type="text"/>
              <br />
              <br />
              <div className="inputlabel">Password</div>
              <input ref="password" type="password"/>
              <div onClick={ this.login.bind(this) } className="submit-button">LOGIN</div>
              <div onClick={ () => {
                  this.setState({showLogin: false});
                  window.location.href="/";
                }} className="cancel-button">CANCEL</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BlockDashboard);